import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  chips: { display: "flex", flexWrap: "wrap", },

  chip: { margin: 2, color: theme.palette.text.primary, border: `2px solid ${theme.palette.primary.pastel}`, },

  chipCheckbox: {
    color: theme.palette.primary.main,
    "&.Mui-checked": { color: theme.palette.primary.main, },
  },

  selectInputField: {
    width: "250px",
    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, },
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary, },
  },
}));

const RatingsSelect = ({ selectedOptions, onChange, styleSelectInputField = true }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const ratings = ["10.00", "7.50", "5.00", "2.50"];
  const ratingsOptions = ["10.00", "7.50", "5.00", "2.50"];



  //  ***************
  //  ** Functions **
  //  ***************
  const handleChange = (event) => { onChange(event.target.value); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div>
      <FormControl
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        classes={styleSelectInputField ? { root: classes.selectInputField } : { }}
      >
        <InputLabel>{i18n.t("ratingsSelect.inputRating")}</InputLabel>

        <Select
          multiple
          label={i18n.t("ratingsSelect.inputRating")}
          value={selectedOptions}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected?.length > 0 && selected.map(selectedRating => {
                const rating = ratings.find(r => r === selectedRating);
                return rating
                  ? (
                    <Chip
                      key={selectedRating}
                      variant="outlined"
                      label={selectedRating}
                      className={classes.chip}
                    />
                  )
                  : null;
              })}
            </div>
          )}
        >
          {ratingsOptions.map(rating => (
            <MenuItem key={rating} value={rating}>
              <Checkbox className={classes.chipCheckbox} checked={selectedOptions.indexOf(rating) > -1} />
              
              <ListItemText primary={rating} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default RatingsSelect;
